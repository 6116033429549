<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png"
             alt
             @click="goBack" />
        <span class="center">工资包</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">工资包列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">工资包详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>工资包基本信息</span>
          </div>
        </div>
        <div class="edit_top">
          <el-card class="border_left">
            <div class="left_top">
              <div class="left_icon">
                <span></span>
                <span>基础信息</span>
              </div>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/task_1.png"
                     alt />
                <span>工资包名称：</span>
              </div>
              <span class="left-demo_right">{{ detailInfo.name }}</span>
            </div>

            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/task_7.png"
                     alt />
                <span>发包时间：</span>
              </div>
              <span class="left-demo_right">{{ detailInfo.releaseTime | dateVal }}</span>
            </div>
            <!-- <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/task_2.png" alt />
                <span>创建时间：</span>
              </div>
              <span class="left-demo_right">{{ detailInfo.releaseTime | dateVal }}</span>
            </div>-->
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/task_3.png"
                     alt />
                <span>撤销原因：</span>
              </div>
              <span class="left-demo_right">
                {{
                detailInfo.cancelMsg
                }}
              </span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/task_8.png"
                     alt />
                <span>备注：</span>
              </div>
              <span class="left-demo_right">{{ detailInfo.remark }}</span>
            </div>
          </el-card>
          <div class="border_right">
            <el-card class="right_one">
              <div class="left_top">
                <div class="left_icon">
                  <span></span>
                  <span>状态信息</span>
                </div>
              </div>
              <div class="left-demo">
                <div class="left-demo_left">
                  <img src="../../assets/task_9.png"
                       alt />
                  <span>工资包状态：</span>
                </div>
                <span class="left-demo_right">{{ detailInfo.state | taskStateVal }}</span>
              </div>
              <div class="left-demo">
                <div class="left-demo_left">
                  <img src="../../assets/task_12.png"
                       alt />
                  <span>支付银行：</span>
                </div>
                <span class="left-demo_right"
                      v-if="detailInfo.payBank">
                  <span v-if="detailInfo.payBank.platformGroup==='myBank2'">网商银行</span>
                  <span v-else>平安银行</span>
                </span>
              </div>
              <div class="left-demo">
                <div class="left-demo_left">
                  <img src="../../assets/task_10.png"
                       alt />
                  <span>员工人数：</span>
                </div>
                <span class="left-demo_right">{{ detailInfo.makerNumber }}人</span>
              </div>
              <div class="left-demo">
                <div class="left-demo_left">
                  <img src="../../assets/task_11.png"
                       alt />
                  <span>员工结算总金额/元：</span>
                </div>
                <span class="left-demo_right">
                  {{
                  detailInfo.total === "0" ? "0" : detailInfo.total | money
                  }}
                </span>
              </div>

              <div class="left-demo">
                <div class="left-demo_left">
                  <img src="../../assets/task_14.png"
                       alt />
                  <span>工资包总金额/元：</span>
                </div>
                <span class="left-demo_right">
                  {{
                  detailInfo.total === "0" ? "0" : detailInfo.total | money
                  }}
                </span>
              </div>
            </el-card>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div class="left_top"
             style="margin-bottom: 20px">
          <div class="left_icon">
            <span></span>
            <span>工资信息</span>
            <span style="margin-left: 9px;color:#999;font-size:12px">共 {{ dispatchList.length }} 条数据</span>
          </div>
          <div v-if="detailInfo.payState==='1'||detailInfo.payState==='3'">
            <el-button type="text"
                       v-if="detailInfo.payBank.platformGroup!='myBank2'"
                       @click="viewPdf(detailInfo.fileName, detailInfo.completeTime)">下载回单</el-button>
            <el-button v-else
                       type="text"
                       @click="seePdf(detailInfo.code)">查看回单</el-button>
          </div>
        </div>
        <div style="text-align: left;margin:10px 0"
             v-if="show">
          <span style="margin-left:10px">支付银行：</span>
          <el-select v-model="pay_platform"
                     style="width: 230px"
                     size="mini"
                     clearable
                     @change="payChange">
            <el-option v-for="(item,index) in payChannelList"
                       :key="index"
                       :label="item.name"
                       :value="item.code"
                       :disabled="item.disabled"></el-option>
          </el-select>
        </div>
        <el-table :data="dispatchList"
                  style="width: 100%">
          <el-table-column prop="username"
                           label="姓名"></el-table-column>

          <el-table-column v-for="(item, index) in headerTitle"
                           :key="index"
                           :label="item">
            <template slot-scope="scope">{{ scope.row[item] }}</template>
          </el-table-column>
          <el-table-column prop="hourSalary"
                           label="时薪"></el-table-column>
          <el-table-column label="出勤工时">
            <template slot-scope="scope">
              <span>{{ scope.row.workDays.length*4 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出勤天数">
            <template slot-scope="scope">
              <span>{{ scope.row.workDays.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="核查结果"
                           v-if="show">
            <template slot-scope="scope"
                      v-if="scope.row.hasOwnProperty('hasBankErr')">
              <span v-if="scope.row.hasBankErr"
                    style="color:red">无相应的银行卡</span>
              <span v-if="!scope.row.hasBankErr">通过</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div class="left_top"
             style="margin-bottom: 20px">
          <div class="left_icon">
            <span></span>
            <span>审批流程</span>
            <span style="margin-left: 9px;color:#999;font-size:12px">如需变更审批人和复核人，请在项目管理中进行操作</span>
          </div>
        </div>
        <el-table :data="flowList"
                  style="width: 100%">
          <el-table-column label="审批人">
            <template slot-scope="scope">
              <span>{{ scope.row.viewerName }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="手机号">
            <template slot-scope="scope">
              <span v-if="scope.row.user">
                <span
                  style="color:blue"
                  v-if="!scope.row.show"
                  @click="scope.row.show = true"
                >{{ scope.row.user.phone.substr(0, 3) + new Array(scope.row.user.phone.length - 7).join('*') + scope.row.user.phone.substr(-4) }}</span>
                <span
                  v-else
                  @click="scope.row.show = false"
                  style="color:blue"
                >{{ scope.row.user.phone }}</span>
              </span>
            </template>
          </el-table-column>-->
          <el-table-column label="审批状态"
                           prop="status">
            <template slot-scope="scope"
                      v-if="detailInfo.state!='0'">{{ scope.row.status | statusVal }}</template>
          </el-table-column>
          <el-table-column label="审批意见"
                           prop="message"></el-table-column>
          <el-table-column label="审批时间"
                           prop="operateTime">
            <template slot-scope="scope"
                      v-if="scope.row.operateTime">{{ scope.row.operateTime | dateVal }}</template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-button v-if="show"
                 :disabled="disShow"
                 type="primary"
                 @click="disGet">下放审批</el-button>
    </el-card>
  </div>
</template>
<script>

import { api } from '../../api/base/index';
// 获取工资包详情
export const taskDescribe = api()('salaryTask.describe.json');
// 获取审批流程
export const salaryTaskCheckFlow = api()('salaryTask.checkFlow.json');
// 结算
export const salaryTaskRegistrationList = api()(
  'salaryTask.registration.list.json'
);
export const taskTamplateInfo = api()(
  'taskTamplate.info.json'
);
export const salaryTaskCompile = api()('salaryTask.compile.json');
export const projectInfo = api()('project.info.json');
export const salaryTaskdDspathCheckPassd = api()('salaryTask.dispathCheckPassd.json');
export const electronicreceiptQuery = api()('electronicreceipt.query.json');

export default {
  data () {
    return {
      code: '',
      detailInfo: {},
      dispatchList: [],
      dispatchTitle: {},
      headerTitle: [],
      obj: {},
      flowList: [],
      idCardShowTitle: '',
      show: null,
      payChannelList: [],
      pay_platform: '',
      disShow: true
    };
  },
  filters: {
    statusVal (status) {
      const map = {
        0: '待审核',
        1: '已审核',
        2: '已拒绝',
      }
      return map[status] || '-'
    }
  },
  created () {
    this.code = this.$route.query.code;
    this.show = this.$route.query.show;

    this.getDetail();
    this.getDispatchList();
    this.getCheckFlow()
  },
  methods: {
    async enterpriselogoData (code) {
      const params = {
        code: code
      };
      await projectInfo(params).then((res) => {
        console.log(res);
        this.payChannelList = res.accounts.map(it => ({
          code: it.platformCode,
          name: it.branchName.includes('网商') ? '网商银行' : '平安银行',
          disabled: it.enable
        }))
      });
    },
    // 下发审批
    async disGet () {
      const params = {
        taskCode: this.code,
        pay_platform: this.pay_platform
      };
      await salaryTaskdDspathCheckPassd(params).then((res) => {
        console.log(res);
        this.$message.success('下发成功')
        this.$router.push({
          path: '/system/task/list',

        });
      })
    },
    // 获取工资包详情
    async getDetail () {
      this.detailInfo = await taskDescribe({ code: this.code });
      this.enterpriselogoData(this.detailInfo.project.code)
    },
    // 获取工资包人员列表
    async getDispatchList () {
      const res = await salaryTaskRegistrationList({
        code: this.code,
        $ignoreRepeat: true
      });

      this.dispatchList = res.list.map(n => ({
        ...JSON.parse(n.other),
        '金额': Number(n.salary)
      }));
      this.obj = this.dispatchList[0]
      var reg = /^[\u4E00-\u9FA5]+$/;
      for (var key in this.obj) {
        if (reg.test(key)) {
          this.headerTitle.push(key)
        }
      }
      this.dispatchTitle = res.list[0].taskTaplate.selectTags.split(',')


    },
    getTime (timestr) {
      const d = new Date(timestr);
      d.setHours(24);
      return Date.now() > (d.getTime() + 14 * 60 * 60 * 1000)
    },
    async payChange () {

      const res = await salaryTaskCompile({
        taskCode: this.code,
        platformCode: this.pay_platform
      });
      this.dispatchList = this.dispatchList.map((it, i) => ({
        ...it,
        hasBankErr: res.data[i].hasBankErr,
      }))
      this.disShow = !res.isPass
      console.log(this.dispatchList);
    },
    viewPdf (name, time) {
      const show = this.getTime(time)
      if (show) {
        window.open(
          `https://file.lgbfss.com/pingan/receipt/${name}`,
          '_blank'
        )
      }
      else {
        this.$message.error(
          '暂无回单，请于交易次日14点之后再进行查询！'
        );
      }
    },
    async seePdf (code) {
      const list = await electronicreceiptQuery({ taskCode: code })
      if (list.status === '01') {
        this.$message.error(
          '暂无回单，稍后重试！'
        );
      }
      else if (list.status === '02') {
        window.open(
          `${list.url}`
        )
      }
      else {
        this.$message.error(
          '回单下载失败，请联系客服！'
        );
      }
      console.log(list);
    },
    idNumberShow (e) {
      e.srcElement.innerText = e.srcElement.innerText.includes('*') ? e.target.attributes.cardNum.value : this.maskIdNum(e.target.attributes.cardNum.value)
    },
    maskIdNum (number) {
      return number.substr(0, 3) + new Array(number.length - 7).join('*') + number.substr(-4)
    },
    async getCheckFlow () {
      const list = await salaryTaskCheckFlow({ code: this.code })
      this.flowList = list.map(it => ({
        ...it,
        show: false
      }));
      console.log(this.flowList);

    },
    goBack () {
      window.history.go(-1);
    }
  }
};
</script>
<style>
.card-box .el-card__body {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.edit_top {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding: 20px 5px;
  width: 100%;
  .border_left {
    width: 48%;
    .left_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .left_icon {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          padding: 8px 4px;
          background: #4977fc;
          margin-right: 10px;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .left-demo {
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 20px 10px;
      border-bottom: 1px dashed #000;
      font-size: 15px;
      .left-demo_left {
        display: flex;
        align-items: center;
        img {
          width: 39px;
          height: 35px;
        }
        span {
          margin-left: 15px;
        }
      }
      .left-demo_right {
        color: #b4b4b4;
      }
    }
    .left_buttom {
      margin: 140px 0 0 0;
      height: 100%;
      flex: 1;
    }
    .left-demo:last-child {
      border: none;
    }
  }
  .border_right {
    width: 48%;
    display: flex;
    flex-wrap: wrap;

    border-radius: 10px;

    .right_one {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      .left_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .left_icon {
          display: flex;
          align-items: center;
          span:nth-child(1) {
            padding: 8px 4px;
            background: #4977fc;
            margin-right: 10px;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .left-demo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        color: #b4b4b4;
        font-size: 15px;
        border-bottom: 1px dashed #000;
        .left-demo_left {
          display: flex;
          align-items: center;
          color: #000;
          img {
            width: 20px;
            height: 23px;
          }
          span {
            margin-left: 15px;
          }
        }
      }
      .left-demo:last-child {
        border-bottom: none;
      }
    }
    .right_one:nth-child(2) {
      margin: 20px 0 0 0;
      .left-demo {
        border-bottom: 1px dashed #000;
      }
      .left-demo:last-child {
        border: none;
      }
    }
  }
}
.basic-info {
  margin-bottom: 100px;
}
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>
